import { render, staticRenderFns } from "./RegisterCustomer.vue?vue&type=template&id=4865739e&scoped=true&"
import script from "./RegisterCustomer.vue?vue&type=script&lang=ts&"
export * from "./RegisterCustomer.vue?vue&type=script&lang=ts&"
import style0 from "./RegisterCustomer.vue?vue&type=style&index=0&id=4865739e&scoped=true&lang=css&"
import style1 from "./RegisterCustomer.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4865739e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4865739e')) {
      api.createRecord('4865739e', component.options)
    } else {
      api.reload('4865739e', component.options)
    }
    module.hot.accept("./RegisterCustomer.vue?vue&type=template&id=4865739e&scoped=true&", function () {
      api.rerender('4865739e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/auth/RegisterCustomer.vue"
export default component.exports