var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "register-customer" },
    [
      _c(
        "CRow",
        [
          _c("CCol", { attrs: { md: "8" } }, [
            _c("h1", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "pages.auth.registerCustomer.title",
                  expression: "'pages.auth.registerCustomer.title'"
                }
              ]
            }),
            _c("div", [
              _vm._v(_vm._s(_vm.$t("pages.auth.registerCustomer.infoText")))
            ]),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.hostLink) } })
          ])
        ],
        1
      ),
      _c(
        "CRow",
        { staticClass: "mt5" },
        [
          _vm.maintenanceMode
            ? _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c(
                    "CCard",
                    [
                      _c("CCardBody", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "pages.auth.registerCustomer.maintenanceMode",
                            expression:
                              "'pages.auth.registerCustomer.maintenanceMode'"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.maintenanceMode
            ? _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c(
                    "CForm",
                    [
                      _c(
                        "CCard",
                        [
                          _c(
                            "CCardBody",
                            [
                              _vm.message
                                ? _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "alert alert-danger",
                                        attrs: { role: "alert" }
                                      },
                                      [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                    )
                                  ])
                                : _vm._e(),
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.user.displayname,
                                  value: _vm.$v.user.displayname.$model,
                                  autocomplete: "username",
                                  "data-testid": "displayName",
                                  translationKey: "registerCustomer.displayName"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.displayname,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [
                                          _c("CIcon", {
                                            attrs: { name: "cil-user" }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3945887885
                                )
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.user.email,
                                  autocomplete: "email",
                                  "data-testid": "email",
                                  value: _vm.$v.user.email.$model,
                                  translationKey: "registerCustomer.email"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.email,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [_vm._v("@")]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3965286753
                                )
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.user.password,
                                  value: _vm.$v.user.password.$model,
                                  autocomplete: "new-password",
                                  "data-testid": "password",
                                  type: "password",
                                  translationKey: "registerCustomer.password"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.password,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [
                                          _c("CIcon", {
                                            attrs: { name: "cil-lock-locked" }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3300492400
                                )
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.user.passwordConfirmation,
                                  value:
                                    _vm.$v.user.passwordConfirmation.$model,
                                  autocomplete: "new-password",
                                  "data-testid": "passwordConfirmation",
                                  type: "password",
                                  translationKey:
                                    "registerCustomer.passwordConfirmation"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.user.passwordConfirmation,
                                      "$model",
                                      $event
                                    )
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend-content",
                                      fn: function() {
                                        return [
                                          _c("CIcon", {
                                            attrs: { name: "cil-lock-locked" }
                                          })
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3300492400
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCard",
                        [
                          _c(
                            "CCardBody",
                            [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "pages.auth.registerCustomer.emailSetting"
                                    )
                                  )
                                }
                              }),
                              _c("hr", { staticStyle: { "margin-top": "0" } }),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "allowEmailForPersonalOffersAt"
                                          ),
                                          name:
                                            "user.allowEmailForPersonalOffersAt",
                                          checked:
                                            _vm.$v.user
                                              .allowEmailForPersonalOffersAt
                                              .$model,
                                          translationKey:
                                            "registerCustomer.allowEmailForPersonalOffersAt",
                                          field:
                                            _vm.$v.user
                                              .allowEmailForPersonalOffersAt
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user
                                                .allowEmailForPersonalOffersAt,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "allowEmailForFeedbackAt"
                                          ),
                                          name: "user.allowEmailForFeedbackAt",
                                          checked:
                                            _vm.$v.user.allowEmailForFeedbackAt
                                              .$model,
                                          translationKey:
                                            "registerCustomer.allowEmailForFeedbackAt",
                                          field:
                                            _vm.$v.user.allowEmailForFeedbackAt
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user
                                                .allowEmailForFeedbackAt,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCard",
                        [
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "businessTerms"
                                          ),
                                          name: "user.businessTerms",
                                          checked:
                                            _vm.$v.user.businessTerms.$model,
                                          translationKey:
                                            "registerCustomer.acceptBusinessTerms",
                                          field: _vm.$v.user.businessTerms
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user.businessTerms,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CCol",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("ValidatedCheckbox", {
                                        attrs: {
                                          isValid: _vm.checkIfValid(
                                            "privacyCheck"
                                          ),
                                          name: "user.privacyCheck",
                                          checked:
                                            _vm.$v.user.privacyCheck.$model,
                                          translationKey:
                                            "registerCustomer.privacyCheck",
                                          field: _vm.$v.user.privacyCheck
                                        },
                                        on: {
                                          "update:checked": function($event) {
                                            return _vm.$set(
                                              _vm.$v.user.privacyCheck,
                                              "$model",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { md: "12" }
                            },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "px-4",
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      _vm.loading || !_vm.checkPrivacyChecked,
                                    "data-testid": "submit-registration"
                                  },
                                  on: { click: _vm.handleSubmit }
                                },
                                [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading,
                                        expression: "loading"
                                      }
                                    ],
                                    staticClass:
                                      "spinner-border spinner-border-sm"
                                  }),
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "t",
                                        rawName: "v-t",
                                        value:
                                          "pages.auth.registerCustomer.button",
                                        expression:
                                          "'pages.auth.registerCustomer.button'"
                                      }
                                    ]
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "CCol",
            { attrs: { md: "4" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("pages.auth.registerCustomer.info.box1.title")
                      )
                    }
                  }),
                  _c("CCardBody", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("pages.auth.registerCustomer.info.box1.text")
                      )
                    }
                  })
                ],
                1
              ),
              _c(
                "CCard",
                [
                  _c("CCardHeader", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "pages.auth.registerCustomer.info.password.title"
                        )
                      )
                    }
                  }),
                  _c("CCardBody", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("pages.auth.registerCustomer.info.password.info")
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showNotification
        ? _c("Notification", {
            attrs: {
              text: _vm.$t("pages.auth.registerCustomer.nofificationText")
            },
            on: {
              onClose: function() {
                return _vm.handleCloseNotification()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }